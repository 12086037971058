export const darkTheme = {
    bg: "#282c34",             
    bgLight: "#353b48",        
    primary: "rgb(71 112 255)",    
    text_primary: "#FFFFFF",   
    text_secondary: "#B3B3B3", 
    card: "#1E2127",           
    card_light: "#222831",     
    button: "#FFD700",         
    accent: "#6B8E23"          
}

export const lightTheme = {
    bg:"#FFFFFF",          
    bgLight: "#f0f0f0",    
    primary:"rgb(71 112 255)",
    text_primary:"#111111", 
    text_secondary:"#48494a", 
    card:"#FFFFFF",        
    button:"rgb(71 112 255)", 
    accent:"#be1adb"       
}
